
.mobile-category-sec {
    display: none;
    margin-bottom: 30px;
}

.all-category-btn {
    padding: 10px 15px;
    border-radius: 7px;
    border: 1px solid #e6e6e6;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.all-category-btn:hover {
    transition: all 0.3s ease-in-out;
    background: #e6e6e6;
}

.all-category-btn i {
    font-size: 14px;
}

.all-category-btn h6 {
    margin: 0;
    font-size: 14px;
    margin-left: 8px;
    text-transform: capitalize;
}


/* sidebar */

.pro-sidebar {
    z-index: 999 !important;
}

.pro-menu-item .pro-inner-item {
    transition: all 0.3s ease-in-out;
    padding: 15px 25px 15px 5px !important;
}

.pro-menu-item .pro-inner-item:hover .pro-item-content {
    background: none;
    color: #E16434 !important;
}

.pro-menu-item.pro-sub-menu .pro-inner-item {
    position: relative;
}

.pro-menu-item.pro-sub-menu .pro-inner-item::before {
    display: none;
}

/* .pro-menu-item .pro-inner-item::after {
    content: "";
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 8px;
    height: 8px;
    transform: translateY(-50%) rotate(39deg);
    background: #696969 !important;
    top: 50%;
    left: 5px;
} */

/* .pro-menu-item.pro-sub-menu .pro-inner-item::after {
    content: "";
    transition: all 0.3s ease-in-out;
    position: absolute;
    width: 8px;
    height: 8px;
    transform: translateY(-50%) rotate(39deg);
    background: #696969 !important;
    top: 50%;
    left: 5px;
} */

.pro-menu-item .pro-inner-item>.pro-item-content {
    font-size: 17px;
}

.pro-menu-item.active .pro-inner-item>.pro-item-content {
    color: #E16434 !important;
}

.pro-menu-item.active .pro-inner-item::after {
    background-color: #E16434 !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item {
    border-bottom: 1px solid #eeee;
}

.pro-menu-item.pro-sub-menu .pro-inner-item:hover::after {
    background: #E16434 !important;
}

.pro-sidebar>.pro-sidebar-inner {
    background: none !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    background: none !important;
}

.pro-item-content {
    color: #000 !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pro-menu-item {
    border-bottom: 1px solid #eee;
    padding: 0px 0;
}

.pro-sub-menu.open .pro-menu-item {
    padding: 0px 0 !important;
}

.pro-sub-menu.open .pro-menu-item:last-child {
    border-bottom: none !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    border-color: rgb(126, 126, 126) !important;
}
.main {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  align-items: center;
}

.left_col {
  display: flex;
  align-items: center;
  gap: 10px;
}

.labelIcon {
  width: 20px !important;
}

.main .left_col h4 {
  font-size: 16px;
}

.couponInputSec {
  background: #eee;
  padding: 10px;
  border-radius: 15px;
  margin-top: 10px;
}

.viewCouponsSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  padding: 0 2px;
}

.input_sec .offerIcon {
  left: 8px;
  font-size: 20px;
}

.input_sec input {
  padding-left: 34px;
}

.viewCouponsSec h5 {
  margin: 0;
  font-weight: 500;
}

.viewCouponsSec button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.viewCouponsSec button:hover {
  color: #e16434;
}

.viewCouponsSec button svg {
  font-size: 18px;
}

.errorMsg {
  color: red;
  font-size: 11px;
  margin: 0;
  margin-top: 4px;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 10;
}

.modal_header {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.closeIcon {
  cursor: pointer;
}

.applied {
  color: #1eab5c;
  margin: 12px 0;
  font-size: 14px !important;
}

.appliedMark {
  color: #1eab5c;
  font-size: 12px;
  margin-left: 10px;
}

.checkIcon {
  width: 17px !important;
  margin-bottom: -7px;
}

.modal_body {
  padding: 15px 20px;
}

.input_sec {
  position: relative;
}

.modal_input {
  width: 100%;
  padding: 13px 60px 13px 15px;
  border: 1px solid #d5d5d5;
  outline: none;
  border-radius: 5px;
}

.input_button {
  position: absolute;
  right: 12px;
  background: none;
  border: none;
  cursor: pointer;
  top: 12px;
  color: #e16434;
}

.error {
  color: red;
  margin: 0;
  margin-top: 5px;
  font-size: 13px;
}

.scrollView {
  height: 400px;
  overflow: hidden;
  overflow-y: auto !important;
  margin-top: 20px;
}

.coupon_item {
  display: flex;
  gap: 15px;
  margin: 15px 0;
}

.coupon_item:first-child {
  margin-top: 0;
}

.coupon_image img {
  width: 80px;
  height: 80px;
}

.coupon_item .title {
  font-size: 15px;
  font-weight: 500;
}

.coupon_item .description {
  font-size: 13px;
  color: #737373;
}

.coupon_item .coupon_code {
  padding: 6px 10px;
  font-size: 12px;
  border: 1px dashed #e16434;
  border-spacing: 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  font-weight: normal;
  margin-bottom: 10px;
  color: #e16434;
  letter-spacing: 1px;
}

.coupon_item .active_coupon_code {
  border-color: #1eab5c;
  color: #1eab5c;
}

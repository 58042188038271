#paymentGatewayListContainer {
    padding: 20px 5px 20px 0;
    width: 100%;
}

#paymentGatewayListContainer input[type=radio] {
    height: 20px;
    width: 20px;
}

.paymentGatewayRow {
    padding: 20px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.paymentOptionName {
    margin-left: 12px;
    font-weight: 600;
}

.paymentGatewayRow label {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.paymentGatewayRow img {
    margin-bottom: 0;
}

.paymentLogo {
    height: 20px;
    margin-left: 10px;
}

.loading {
    padding: 25px;
}

.payNowButton,
.loadingButton {
    width: 150px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #E16434;
    border-radius: 20px;
    background: #E16434;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
}

.payNowButton svg,
.loadingButton svg {
    font-size: 20px !important;
}

.loadingButton {
    background-color: #ccc;
    position: relative;
    border: 1px solid #ccc;
}

.loadingButton .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadingButton span {
    height: 24px;
    width: 24px;
    border: 2px solid #E16434;
    border-radius: 50%;
    border-top-color: transparent;
    display: inline-block;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.payNowButton:hover,
.loadingButton:hover {
    /* border: 1px solid rgb(86, 86, 86); */
    opacity: 0.8;
}

.payNowButtonContainer {
    display: inline-block;
}

.errorMsg {
    color: red;
    padding: 15px;
}
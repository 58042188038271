.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 10;
}

.modal_header {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.closeIcon {
  cursor: pointer;
}

.checkIcon {
  width: 17px !important;
  margin-bottom: -7px;
}

.modal_body {
  padding: 15px 20px;
}

.scrollView {
  overflow: hidden;
  overflow-y: auto !important;
  height: calc(100vh - 130px);
  padding-right: 10px;
  margin-right: -10px;
  padding-bottom: 40px;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

.discountTag {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-top: 20px;
  margin: 0 auto;
  margin-bottom: 8px;
}

.discountTag img {
  width: 100%;
}

.discountNum {
  position: absolute;
  left: 50%;
  bottom: 17%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  font-weight: 900;
  color: #fff;
}

.extraDiscountBtn {
  width: 100%;
  margin: 0 0 12px !important;
  text-transform: uppercase !important;
  border-radius: 20px !important;
}

.noThanksBtn {
  background: #d5d5d5 !important;
  color: #000 !important;
}

.container {
  text-align: center;
  margin: 5px auto 25px;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
}

@media (max-width: 500px) {
  .container {
    padding-bottom: 50px;
  }
}

.successMessage {
  color: #4caf50;
  margin-bottom: 0px;
}

.details {
  margin: 20px 0 30px;
  border-radius: 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

body {
  margin: 0;
  font-family: "Product Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

::selection {
  background: #286aa7;
  color: #fff;
}

.productGridlist::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #f5f5f5;
}

.productGridlist::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 20px;
}

.productGridlist::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}

body.add-space .empty-strip {
  height: 140px !important;
  background-color: #0b3054;
}

* {
  font-family: "Product Sans";
  letter-spacing: 0.4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Cardo";
  src: local("Product Sans"),
    url("./assets/fonts/CARDO-REGULAR.TTF") format("truetype");
}

@font-face {
  font-family: "Cardo";
  src: local("Product Sans"),
    url("./assets/fonts/CARDO-REGULAR.TTF") format("truetype");
}

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"),
    url("./assets/fonts/Product-Sans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Product Sans";
  src: local("Open Sans"),
    url("./assets/fonts/Product-Sans-Regular.ttf") format("truetype");
}

.carousel-btn {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.7);
  min-width: 43px;
  min-height: 43px;
  top: 50%;
  color: #fff;
  cursor: pointer;
}

.carousel-btn img {
  width: 12px;
  height: 20px;
}

.carousel-btn:hover {
  background: black;
}

.left {
  left: 5px;
}

.right {
  right: 5px;
}

.disabled {
  background: black;
  opacity: 0.4;
  cursor: not-allowed;
}

@media (max-width: 500px) {
  .input-lable {
    display: none;
  }

  .input-sec {
    width: 100% !important;
    text-align: center;
  }

  .empty-strip {
    height: 80px;
    margin-top: -2px;
    background-color: #0b3054;
  }

  body.add-space #kiwi-big-iframe-wrapper {
    bottom: 80px !important;
    right: 6px !important;
  }

  #kiwi-big-iframe-wrapper {
    display: none !important;
  }
}

.shining-flash-button {
  overflow: hidden !important;
  position: relative;
}

/* Continuous shining flash animation */
.shining-flash-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -60px;
  width: 60px;
  height: 100%;
  background: #eee;
  box-shadow: 0 0 10px #fff;
  transform: skewX(-45deg);
  filter: blur(10px);
  animation: shining 1.5s infinite linear;
  opacity: 0.2;
}

/* Keyframes for the continuous animation */
@keyframes shining {
  0% {
    left: -300px;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 100%;
  }
}

.placeholder-img-div {
  height: 100%;
  width: 100%;
  background: #ececec;
  position: absolute;
  top: 0;
}

.placeholder-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background: linear-gradient(90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  background-size: 200% 100%;
  animation: pulse 2s infinite, shimmer 2s infinite linear;
}

.hidden {
  display: none;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
